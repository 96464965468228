export default function (Component) {
      Component.options.frontmatter = {
  "published": "2017-03-05T00:00:00.000Z",
  "updated": "2019-05-16T00:00:00.000Z",
  "tags": [
    "vue",
    "javascript"
  ],
  "collection": "Vue",
  "title": "Fiddle with Vue.js",
  "excerpt": "<p>If you’ve created an issue on Vue.js repository for the first time then there is high probability that you skipped reading the contribution guidelines. By doing so you’ve created an issue that does not tell maintainers a thing and helps nobody.\nNext, you would be hit with a template response and your issue would be labelled need repro.</p>\n"
}
    }